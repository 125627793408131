const isValidUrl = url => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export const goBack = ({ history, onClick, isHistoryBackFirst, historyStore }) => {
  const refferel = document.referrer
  const refferelHostname = isValidUrl(refferel) ? new URL(refferel ?? '')?.hostname : undefined
  const hostname = new URL(window.location.href)?.hostname

  if (onClick) {
    onClick()
  } else if (historyStore?.previousFullPage) {
    history.push(historyStore.previousFullPage)
  } else if (
    (!refferel || refferelHostname === hostname) &&
    history &&
    history.length &&
    history?.goBack &&
    isHistoryBackFirst
  ) {
    history?.goBack()
  } else if (history) {
    let pathString = history?.location?.pathname || ''

    if (pathString.endsWith('/')) pathString = pathString.slice(0, -1)

    const path = pathString.split('/')
    if (path.length < 2) {
      if (history.length && history?.goBack) {
        return history.goBack()
      }
      history.push('/')
      return
    }

    path.pop()

    let pathname = path.join('/')

    history.push(pathname)
  }
}
