import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ReactPortal } from '~src/new/shared/ui/ReactPortal/ReactPortal'
import cls from './modal.module.scss'
import PopupBarModule from '~components/molecules/PopupBarModule/PopupBarModule'
import Text from '~src/new/shared/ui/Text/Text'
import Icon from '@material-ui/core/Icon'

export const ModalFront = props => {
  const {
    className,
    classNameBody,
    classNameOverlay,
    popupBarStyle,
    children,
    isOpen,
    onClose,
    withBackButton,
    onBackClick,
    contentStyle,
    headerTitle,
    title,
    parent,
    footerElement,
    noPortal,
    hideCross,
    isPictureShow,
    animateFrom,
  } = props

  const timerRef = useRef(null)

  const closeHandler = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const onContentClick = e => {
    e.stopPropagation()
  }

  const onKeyDown = useCallback(
    e => {
      if (e.key === 'Escape') {
        closeHandler()
      }
    },
    [closeHandler],
  )

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown)
      document.querySelector('body').style.overflow = 'hidden'
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      window.removeEventListener('keydown', onKeyDown)
      document.querySelector('body').style.removeProperty('overflow')
    }
  }, [onKeyDown, isOpen])

  const mods = {
    [cls.opened]: isOpen,
    [cls['from-' + animateFrom]]: animateFrom,
  }

  const withFooter = Boolean(footerElement)

  const ReactPortalNode = noPortal ? React.Fragment : ReactPortal

  return (
    <ReactPortalNode element={parent}>
      <div className={classNames(cls.modal, mods)}>
        <div className={classNameOverlay || cls.overlay} onClick={closeHandler}>
          <div
            className={classNames(cls.content, className, withFooter && cls.withFooter)}
            style={contentStyle}
            onClick={onContentClick}
          >
            {!isPictureShow && (
              <PopupBarModule
                className={cls.header}
                style={popupBarStyle}
                title={headerTitle}
                onClose={onClose}
                isFakePopup={false}
                noPadding={true}
                withCross={!hideCross}
                withBackButton={withBackButton}
                backButtonClassName={cls.backButton}
                onBackClick={onBackClick}
                id="popup-header"
              />
            )}

            {/*DEPRECATED*/}
            {title && <Text className={cls.title}>{title}</Text>}
            {/*---------*/}
            {isOpen ? (
              <>
                <div className={classNames(cls.body, classNameBody)}>{children}</div>
                {footerElement ? <div className={cls.footer}>{footerElement}</div> : null}
              </>
            ) : null}
          </div>
        </div>
        {isPictureShow && (
          <Icon className={cls.closeIcon} onClick={onClose}>
            close
          </Icon>
        )}
      </div>
    </ReactPortalNode>
  )
}

ModalFront.propTypes = {
  className: PropTypes.string,
  classNameBody: PropTypes.string,
  popupBarStyle: PropTypes.object,
  parent: PropTypes.node,
  noPortal: PropTypes.bool,
  hideCross: PropTypes.bool,

  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  withBackButton: PropTypes.bool,
  onBackClick: PropTypes.func,

  contentStyle: PropTypes.object,

  headerTitle: PropTypes.string,

  footerElement: PropTypes.node,

  animateFrom: PropTypes.oneOf(['bottom']),
}
