import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import cls from './button.module.scss'
import Icon from '@material-ui/core/Icon'
import { Link } from 'react-router-dom'

const VARIATIONS = {
  OUTLINE: cls.outline,
  PLAIN: cls.plain,
  ICON: cls.icon,
}

/**
 * Button component.
 *
 * @param {object} props - The component props.
 * @param {JSX.Element|string} [props.children] - The content of the button.
 * @param {'OUTLINE' | 'PLAIN' | 'ICON'} [props.variant] - The variant of the button. Can be 'OUTLINE' or 'PLAIN'.
 * @param {string} [props.className] - Additional class name(s) for the button.
 * @param {React.CSSProperties} [props.style] - Additional inline styles for the button.
 * @param {boolean} [props.isArrow] - Whether the button should be displayed as an arrow.
 * @param {any} [props.ref] - The ref for the button.
 * @param {string} [props.to] - The link to navigate to when the button is clicked.
 * @param {boolean} [props.disabled] - Whether the button is disabled.
 * @param {boolean} [props.loading] - Whether the button is in a loading state.
 * @param {import('react').MouseEventHandler<HTMLButtonElement>} [props.onClick] - The click event handler for the button.

 */
export const Button = ({ children, variant, className, isArrow, loading, ...props }) => {
  let content = children
  if (isArrow) {
    content = <Icon>keyboard_arrow_right</Icon>
    !variant && (variant = 'PLAIN')
  }

  let Container = 'button'

  if (props.to) {
    Container = Link
  }

  return (
    // @ts-ignore
    <Container
      className={classNames(cls.button, VARIATIONS[variant], className, {
        [cls.arrow]: isArrow,
        [cls.loading]: loading,
      })}
      {...props}
    >
      {content}
    </Container>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['OUTLINE', 'PLAIN', 'ICON']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  isArrow: PropTypes.bool,
}
