import React from 'react'
import classNames from 'classnames'
import cls from './grid.module.scss'

const SIZES = ['xs', 'sm', 'md', 'lg', 'xl']

/**
 * Grid component that can act as a container or an item.
 *
 * @param {GridProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered Grid component.
 */
export const Grid = props => {
  const { children, className, page, container, item, col } = props
  if (page) return <div className={classNames(cls.page, className)}>{children}</div>
  const classNameSettings = {
    [cls.gridContainer]: container,
    [cls.gridItem]: item || !container,
    [cls[`col-${col}`]]: col,
  }

  SIZES.forEach(size => {
    if (props[size]) {
      classNameSettings[cls[`col-${size}-${props[size]}`]] = true
    }
  })

  return <div className={classNames(className, classNameSettings)}>{children}</div>
}
